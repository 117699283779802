"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  getVotingBulletin: 'Получить бюллетени для голосования',
  get: 'Получить',
  selectSettingsForPrinting: 'Для удобства работы с бюллетенями, выберите настройки для печати скачиваемых файлов:',
  singleSidedPrinting: 'Односторонняя печать',
  doubleSidedPrinting: 'Двусторонняя печать',
  sortByLastName: 'Сортировка по фамилиям',
  sortByAppartaments: 'Сортировка по помещениям',
  youWillReceiveBulletinsOnYourEmail: 'Вы получите все бюллетени на свою почту <span style="color: #2e87ff">{{ email }}</span> в течение 15 минут'
};