"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  representativeInfo: 'Information on the trusted participant in the meeting',
  addRepresentative: 'Add a representative',
  addRepresentativeComment: "Must be specified if the owner's representative participates in voting",
  representativeLawDocument: 'Authorization document',
  representativePassportDocument: "Representative's document",
  procuration: 'Power of attorney',
  edit: 'edit',
  reverse: 'Cancel',
  addFIO: 'Enter your full name',
  addEmail: 'Specify mail',
  addDescription: 'For example, passport number, by whom issued; additional phone number',
  noInfo: 'No information available',
  addDocuments: 'Add documents',
  comebackToVoting: 'Back to voting',
  dontHaveVotingLimitWarning: '<p>You have already voted for free twice. All subsequent votes in this meeting are paid.</p>' + '<p>The cost of each additional vote is 100 rubles. If you truly wish to change your vote again, you must first pay the fee.</p>',
  nowYouCanChangeYourVoting: '<div style="display: flex; flex-direction: column; gap: 20px">' + '<p>Now you can vote again.</p>' + '<p>All data for your order has been sent to your e-mail <strong>{{ email }}</strong></p>' + '</div>',
  dontHavePhoneWarning: '<div style="display: flex; flex-direction: column; gap: 20px">' + '<p>This meeting requires mandatory two-factor authentication for participants. A simple electronic signature is used for this purpose.</p>' + '<p>Each participant must have a verified mobile phone number in their profile, to which the authentication codes will be sent.</p>' + '<p>Your profile does not contain a verified mobile phone number, and we are unable to complete the two-factor authentication.</p>' + '<p>Please go to your profile, add and verify your mobile phone number. After that, return to the meeting and cast your vote.</p>' + '</div>',
  voteWarning: '<div style="display: flex; flex-direction: column; gap: 12px">' + '<p>You did not vote on all the issues on the agenda. You can return to the voting and vote until the end.</p>' + '<p>Or you can save the voting results and vote again later.</p>' + '</div>'
};