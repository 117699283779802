"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  representativeInfo: 'Информация о доверенном лице участника собрания',
  addRepresentative: 'Добавить представителя',
  addRepresentativeComment: 'Указывается обязательно, если участие в голосовании принимает представитель собственника',
  representativeLawDocument: 'Документ удостоверяющий полномочия',
  representativePassportDocument: 'Документ представителя',
  procuration: 'Доверенность',
  edit: 'редактировать',
  reverse: 'Отменить',
  addFIO: 'Укажите ФИО',
  addEmail: 'Укажите почту',
  addDescription: 'Например, номер паспорта, кем выдан; доп. телефон',
  noInfo: 'Информация отсутствует',
  addDocuments: 'Добавить документы',
  voteWarning: '<div style="display: flex; flex-direction: column; gap: 12px">' + '<p>Вы проголосовали не по всем вопросам повестки дня. Вы можете вернуться в голосование и проголосовать до конца.</p>' + '<p>Или Вы можете сохранить результаты голосования и позже еще раз проголосовать.</p>' + '</div>',
  dontHaveVotingLimitWarning: '<p>Вы уже дважды бесплатно проголосовали.\nВсе последующие голосования в данном собрании платные.</p>' + '<p>Стоимость каждого нового голосования - 100 рублей.  Если Вы действительно хотите еще раз изменить  свое голосование, необходимо предварительно оплатить тариф.</p>',
  paymentWarning: '<p style="font-weight: 600; color: #EC586A">ВАЖНО!!!</p>' + '<p>Если Вы недавно оплатили тариф, но голосование все еще не доступно, нужно немного подождать, пока мы ' + 'получим информацию от платежного сервиса. Это может занять до 20 минут с момента оплаты.</p>',
  comebackToVoting: 'вернуться к\nголосованию',
  nowYouCanChangeYourVoting: '<div style="display: flex; flex-direction: column; gap: 20px">' + '<p>Теперь Вы можете еще раз изменить свое голосование.</p>' + '<p>На ваш e-mail <strong>{{ email }}</strong> отправлены все данные по заказу.</p>' + '</div>',
  dontHavePhoneWarning: '<div style="display: flex; flex-direction: column; gap: 20px">' + '<p>В этом собрании применяется обязательная двойная аутентификация участников собрания. Для этого используется простая электронная подпись.</p>' + '<p>Каждый участник собрания должен иметь подтверждённый мобильный телефон в своём профиле, на который будут высылаться соответствующие коды аутентификации.</p>' + '<p>В Вашем профиле нет подтверждённого номера мобильного телефона и мы не можем провести двойную аутентификацию.</p>' + '<p>Перейдите в свой профиль, добавьте и подтвердите номер мобильного телефона. После этого возвращайтесь в собрание и голосуйте.</p>' + '</div>'
};