"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  attention: 'Important!',
  invalidValue1: 'You are trying to enter an invalid value in the Object Settings ',
  invalidValue2: ' Number of spaces.',
  registryInfo1: 'Note that the register of proprietors now includes ',
  registryInfo2: " rooms. The number of rooms in the Object Settings cannot be less than the number of rooms currently in the owners' register. Enter the correct number of rooms in the Settings or reduce the number of rooms in the register.",
  cadastralInfo1: '1. You need to first reduce the number of premises in the registry to ',
  cadastralInfo2: ' pieces. Now the registry has ',
  cadastralInfo3: ' number of spaces.',
  cadastralInfo4: '2. The maximum number of rooms in your home cannot be more than ',
  cadastralInfo5: ' pieces. This follows from the data of the USRN for an apartment building with a cadastral number: ',
  cadastralInfo6: '. If you think that this cadastral number is incorrect and you want to change it, ',
  support: 'contact tech support.',
  apartmentAmount: ' The total number of rooms in houses cannot exceed {{count}} units.',
  cadastralNumberInfo5: 'Based on the number of rooms in your house, we calculate the cost of individual paid services. Therefore, it is important to use the correct values to generate an invoice correctly. Previously, when purchasing limits (meetings, recognition, simple electronic signature) you specified the number of rooms in the settings. According to these data, a tariff was chosen that limits the total number of rooms in the houses {{count}}.',
  cadastralNumberInfo6: 'If you want to change this restriction, please contact ',
  technicalSupport: 'technical support',
  canBeEmpty: 'The settings are frozen, you can`t keep the void',
  areaInfo: "You are trying to enter a value that is less than the total area of the premises in the owners' register. It cannot be less than {{count}} m2. If you want to enter a smaller value, go to the owners' register and reduce the floor area.",
  maxAreaError: 'You are trying to enter a value of {{area}} m2 that is greater than the allowed maximum floor area. It cannot be greater than {{max}} m2. ',
  maxAmountError: 'You are trying to enter a value of {{amount}} pcs. that is greater than the allowed maximum number of rooms. It cannot be greater than {{max}} pcs. ',
  villageInfo1: "Can't be less than ",
  villageInfo2: 'The ownership registry now has ',
  villageInfo3: " pcs. of premises. The number of rooms in the house settings cannot be less than in the owners' register. Enter the correct number or go to the register and edit the number of rooms.",
  villageInfo4: 'You have previously paid one-time fees for meeting and/or ballot recognition. The cost of these services depends on the number of buildings in the village. Therefore, we have already recorded these values when you pay.',
  villageInfo5: 'If you would like to change the already fixed values for the number of residential and non-residential buildings for your settlement, please contact ',
  villageInfo6: ' to make changes.'
};