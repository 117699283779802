"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  attention: 'Внимание!!!',
  invalidValue1: 'Вы пытаетесь ввести в Настройках Объекта невалидное значение ',
  invalidValue2: ' штук помещений.',
  registryInfo1: 'Обратите внимание, что в реестре собственников сейчас имеется ',
  registryInfo2: ' помещений. Количество помещений в Настройках Объекта не может быть меньше, чем сейчас имеется в реестре собственников. Введите правильное количество помещений в Настройках или уменьшите количество помещений в реестре.',
  cadastralInfo1: '1. Вам необходимо сначала уменьшить количество помещений в реестре до ',
  cadastralInfo2: ' штук. Сейчас в реестре есть ',
  cadastralInfo3: ' штук помещений.',
  cadastralInfo4: '2. Максимальное количество помещений в Вашем доме не может быть больше, чем ',
  cadastralInfo5: ' штук. Это вытекает из данных ЕГРН для многоквартирного дома с кадастровым номером: ',
  cadastralInfo6: '. Если Вы считаете, что данный кадастровый номер неправильный и хотите его изменить, ',
  support: 'обратитесь в тех. поддержку.',
  apartmentAmount: ' Общее количество помещений в домах не может превышать {{count}} штук.',
  cadastralNumberInfo5: 'На основании количества помещений в вашем доме мы рассчитываем стоимость отдельных платных услуг. Поэтому для корректного формирования счета важно использовать правильные значения. Ранее при покупке лимитов (собрания, распознавание, простая электронная подпись) вы указывали количество помещений в настройках. Согласно этим данным был выбран тариф, который ограничивает общее количество помещений в домах {{count}}.',
  cadastralNumberInfo6: 'Если вы хотите изменить это ограничение обратитесь ',
  canBeEmpty: 'Настройки заморожены, нельзя сохранять пустоту',
  technicalSupport: 'в техническую поддержку',
  areaInfo: 'Вы пытаетесь ввести значение, которое меньше, чем общая площадь помещений в реестре собственников. Оно не может быть меньше {{count}} м2. Если Вы хотите ввести меньшее значение, перейдите в реестр собственников и уменьшите площадь помещений.',
  maxAreaError: 'Вы пытаетесь ввести значение {{area}} м2, которое больше, чем допустимая максимальная площадь помещений. Оно не может быть больше {{max}} м2. ',
  maxAmountError: 'Вы пытаетесь ввести значение {{amount}} шт., которое больше, чем допустимое максимальное количество помещений. Оно не может быть больше {{max}} шт. ',
  villageInfo1: 'Не может быть меньше, чем ',
  villageInfo2: 'В реестре собственников сейчас имеется ',
  villageInfo3: ' шт. помещений. Количество помещений в настройках дома не может быть меньше, чем в реестре собственников. Введите правильное количество или перейдите в реестр и отредактируйте количество помещений.',
  villageInfo4: 'Ранее Вы уже оплачивали разовые тарифы для проведения собрания и/или распознавания бюллетеней. Стоимость этих услуг зависит от количества строений в посёлке. Поэтому при оплате мы уже зафиксировали эти значения.',
  villageInfo5: 'Если Вы хотите изменить уже зафиксированные значения количества жилых и нежилых строений для Вашего посёлка, обратитесь пожалуйста ',
  villageInfo6: ' для внесения изменений.'
};