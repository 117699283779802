"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  modalTitle: 'Двойная аутентификация',
  titleInfoModal: 'Двойная аутентификация участников голосования',
  votingWithPEPText1: 'Для голосования в данном собрании используется простая электронная подпись (ПЭП).',
  textWithMobileNumber: 'На Ваш ранее подтвержденный номер мобильного телефона <span style="font-weight: bold">{{phone}}</span> будет отправлено СМС с кодом.',
  enterCodeText: 'Введите полученный код ниже.',
  votingWithPEPHelperText1: `У Вас есть возможность проголосовать бесплатно дважды, включая этот раз. Третье и каждое следующее голосование будет стоить Вам {{cost}} рублей.`,
  votingWithPEPHelperText2: 'Вы уже однажды проголосовали. Вы можете проголосовать еще один раз. Каждое последующее голосование - это платный функционал. Его стоимость {{cost}} рублей.',
  votingWithPEPHelperText3: 'Если Вы хотите использовать другой номер телефона, перейдите в свой профиль, измените и подтвердите новый номер телефона.',
  successToastTitle: 'Ваш голос учтен',
  successToastText1: 'Поздравляем! Вы успешно проголосовали и можете переголосовать бесплатно еще один раз. Если захотите переголосовать после второго раза, то Вам придется заплатить за каждое новое голосование - {{cost}} рублей.',
  successToastText2: 'Поздравляем! Вы успешно проголосовали. Если захотите переголосовать еще раз, то Вам придется заплатить за каждое' + ' новое голосование {{cost}} рублей.',
  votingWithPEPInfoText1: 'В этом собрании все его участники, использующие электронное голосование, обязаны пройти ' + 'двойную аутентификацию. Для нее используется ПЭП (простая электронная подпись).',
  votingWithPEPInfoText2: 'Выберите для каждого проекта решения свой вариант голосования (ЗА, ПРОТИВ, ВОЗДЕРЖАТЬСЯ). ' + 'После того как Вы проголосуете по всем вопросам повестки дня, нажмите на кнопку «ПРОГОЛОСОВАТЬ» в нижней части ' + 'экрана.',
  votingWithPEPInfoText3: 'На Ваш телефонный номер будет выслано СМС с проверочным кодом, который нужно ввести в специальной ' + 'форме. Только после такого подтверждения Ваше голосование будет сохранено в результатах собрания.',
  votingWithPEPInfoText4: 'Каждый участник собрания имеет возможность электронно проголосовать и потом один раз ' + 'изменить свое голосование бесплатно. Третье и все последующие голосования - платная опция. ' + 'Поэтому рекомендуем Вам сразу голосовать аккуратно и внимательно.',
  votingWithPEPInfoText5: 'Вы всегда сможете проголосовать бумажным бюллетенем.',
  yourVotingIsNotSaved: 'Ваше голосование не сохранено'
};