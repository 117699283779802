"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  copySuccess: 'Ссылка на профиль скопирована!',
  lastSendLessThanHour: 'Приглашение было отправлено менее часа назад',
  lastSend: 'Приглашение было отправлено %hour% назад',
  contactIsAlreadyInUse: 'Данный {{contactType}} уже используется в сервисе Сквер',
  contactTypePhone: 'телефон',
  contactTypeEmail: 'e-mail',
  blackListAdd: 'Пользователь успешно добавлен в черный список',
  blackListDelete: 'Пользователь успешно удален из черного списка',
  favoriteAdd: 'Пользователь успешно добавлен в избранное',
  favoriteDelete: 'Пользователь успешно удален из избранного',
  addUserInJoinOwnership: 'В помещении № {{apartmentNumber}} совместная собственность, новый собственник будет добавлен к',
  allowMeetingSuccess: 'Успешно изменена возможность участия в голосовании у пользователя',
  allowMeetingError: 'Произошла ошибка при смене статуса  участия в голосовании',
  ownershipsEditingWarning: 'Вы можете редактировать только документы на право собственности. Для изменения данных о собственности обратитесь к администратору объекта.',
  invitingToSqvr: 'Приглашаем в Сквер',
  registrationCodeSentTo: 'Регистрационный код успешно отправлен пользователю на {{contactValue}}',
  registrationCodeSentTo1: 'Регистрационный код успешно отправлен пользователю на ',
  registrationCodeSendingError: 'Ошибка при отправке кода регистрации',
  checkEmail: 'Проверьте email',
  checkSMS: 'Проверьте sms',
  emailVerificationSent: 'Мы отправили вам письмо на адрес {{email}}. Для подтверждения адреса электронной почты пройдите по ссылке в сообщении.',
  phoneVerificationSent: 'Мы отправили вам sms на номер {{phone}}. Для подтверждения телефонного номера пройдите по ссылке в сообщении.',
  emailVerificationError: 'Ошибка при подтверждении e-mail',
  emailVerifiedSuccessfully: 'E-mail «{{email}}» успешно подтвержден',
  contactEmailVerificationRequired: 'Пока не подтвержден. Контактное лицо не получает уведомления.',
  contactEmailVerificationSend_0: 'Подтверждение отправлено. Следующее подтверждение можно будет отправить через {{count}} минуту.',
  contactEmailVerificationSend_1: 'Подтверждение отправлено. Следующее подтверждение можно будет отправить через {{count}} минуты.',
  contactEmailVerificationSend_2: 'Подтверждение отправлено. Следующее подтверждение можно будет отправить через {{count}} минут.',
  notMainEmailVerifiedSuccessfullyPart1: 'Вы подтвердили свой email: {{email}}, если вы хотите получать на него уведомления сделайте его "Основным" нажав на',
  notMainEmailVerifiedSuccessfullyPart2: 'рядом с контактом',
  notMainPhoneVerifiedSuccessfullyPart1: 'Вы подтвердили свой телефон: {{phone}}, если вы хотите получать на него уведомления сделайте его "Основным" нажав на',
  notMainPhoneVerifiedSuccessfullyPart2: 'рядом с контактом',
  phoneVerificationError: 'Ошибка при подтверждении телефонного номера',
  phoneVerifiedSuccessfully: 'Телефонный номер «{{phone}}» успешно подтвержден',
  recoverPasswordDialogTitle: 'Восстановление пароля',
  recoverPasswordDialogMessage: `<p>
Если Вы не можете вспомнить и хотите поменять свой старый пароль, то Вы можете начать процедуру восстановления пароля. После этого:<br />
<br />
1. Мы поменяем пароль и вышлем его Вам в сообщении.<br />
2. Скопируйте новый пароль из сообщения.<br />
3. Используйте этот пароль для авторизации в свой Объект.<br />
4. Откройте свой профиль, выберите вкладку “Смена пароля” и на место старого пароля вставьте скопированный пароль.<br />
5. Придумайте и запомните свой новый пароль.<br />
<br />
Текущая сессия будет завершена. Продолжить?
</p>`,
  socialNetworkUserPasswordWarning: 'В аккаунте использована авторизация через социальную сеть {{socialNetwork}}. Изменение пароля производится в аккаунте социальной сети.',
  emailVerificationSentSuccessefuly: 'Письмо с предложением подтвердить адрес электронной почты было успешно отправлено контактному лицу на e-mail: {{email}}',
  emailVerificationSendFail: 'Ошибка при отправке письма!',
  inMinutes_0: '{{count}} минуту',
  inMinutes_1: '{{count}} минуты',
  inMinutes_2: '{{count}} минут',
  inSeconds_0: '{{count}} секунду',
  inSeconds_1: '{{count}} секунды',
  inSeconds_2: '{{count}} секунд',
  verificationSmsHasAlreadySent: 'Sms отправлено. Повторить через {{timeLeft}}',
  verificationEmailHasAlreadySent: 'Письмо отправлено. Повторить через {{timeLeft}}',
  resendingWillAvailableIn: 'Повторная отправка будет доступна через {{minutes}}',
  registrationSmsHasAlreadySent: 'Sms с приглашением отправлено. Повторная отправка будет доступна через {{minutes}}',
  emailHasSent: 'E-mail успешно отправлен!',
  smsHasSent: 'Sms успешно отправлено!',
  toggleMainEmailMessage: 'На указанный e-mail {{email}} было отправлено письмо с подтверждением',
  toggleMainPhoneMessage: 'На указанный телефон {{phone}} было отправлено sms с подтверждением',
  inThisHOA: 'в этом же объекте',
  inAnotherHOA: 'в другом объекте',
  toggleMainContactConflict1: 'Вы указали {{contactType}}, который уже используется',
  toggleMainContactConflict2: 'другим пользователем',
  toggleMainContactConflict3: 'Обращаем Ваше внимание, что если',
  toggleMainContactConflict4: 'подтвердит использование контакта, то Ваш профиль будет удалён из сервиса Сквер, а',
  toggleMainContactConflict5: 'станет собственником всех Ваших помещений.',
  toggleMainContactConflict6: 'Вы уверены, что хотите отправить подтверждение на этот {{contactType}}?',
  inviteConflictWarning1: 'Вы указали {{contactType}}, который уже используется',
  inviteConflictWarning2: 'другим пользователем',
  inviteConflictWarning3: 'Обращаем Ваше внимание, что если',
  inviteConflictWarning4: 'примет приглашение, то информация о пользователе будет заменена на информацию из другого объекта.',
  inviteConflictWarning5: 'Вы уверены, что хотите отправить приглашение на этот {{contactType}}?',
  inviteUsersConflictWarning1: 'Вы указали {{contactType}}, который уже используется',
  inviteUsersConflictWarning2: 'другим пользователем',
  inviteUsersConflictWarning3: 'При этом есть несовпадения по полям:',
  inviteUsersConflictWarning4: 'Обращаем Ваше внимание, что если',
  inviteUsersConflictWarning5: 'примет Ваше приглашение, то заменит собой собственника',
  inviteUsersConflictWarning6: 'Вы уверены, что хотите отправить приглашение на этот {{contactType}}?',
  registered: 'Зарегистрирован',
  invite: 'Приглашение',
  toggleMainEmailWarning: '<p style="margin-bottom: 16px">При смене основного адреса электронной почты все ранее отправленные коды регистрации станут недействительны!</p><p  style="margin-bottom: 16px">Вы сможете отправить их заново на новый основной адрес электронной почты.</p>',
  toggleMainContactSmsLimitExceed: 'В Вашем Объекте нет лимита на отправку СМС сообщений. Для того, чтобы подтвердить новый телефон Вы можете обратиться к любому Модератору или Администратору, чтобы они оплатили тариф, в котором есть рассылки СМС сообщений.',
  toggleMainContactSmsLimitExceedAM: 'Или Вы можете самостоятельно оплатить <a href="{{link}}">тариф</a> для отправки СМС в Вашем Объекте',
  contactExpertConflict: 'Данный {{contactType}} уже используется в сервисе Сквер для специальной роли “эксперт”. Вы не можете использовать этот {{contactType}}. Введите новый {{contactType}}',
  telegramLinkSuccess: 'Ссылка для подключения успешно отправлена пользователю {{user}}',
  contactRegisteredConflict: 'Этот номер {{contactType}} уже зарегистрирован за другим пользователем в сервисе Сквер. Используйте другой {{contactType}}',
  notConfirmed: 'Пока не подтвержден. Вы не можете его использовать как средство авторизации и получать на него сообщения.',
  emailNotConfirmed: 'E-mail пока не подтвержден. После подтверждения на него можно будет отправлять сообщения. Пользователь сможет использовать этот e-mail при авторизации.',
  phoneNotConfirmed: 'Телефон пока не подтвержден. После подтверждения на него можно будет отправлять сообщения. Пользователь сможет использовать этот номер телефона при авторизации.',
  modalText1: '1. Мы проверим используется ли он в сервисе Сквер.',
  modalText2: '2. В случае, если этот e-mail ещё не используется в сервисе Сквер, мы отправим письмо с уникальной ссылкой, после клика на которую e-mail контактного лица будет потверждён.',
  modalText3: '3. В случае если этот e-mail уже используется, то в письме будет инструкция по дальнейшим действиям по его смене.',
  modalText4: '4. До тех пор, пока контактное лицо не подтвердит новый e-mail, он не будет получать сообщения от Сквера.',
  send: 'Да. Отправить',
  noSend: 'Не отправлять. Назад',
  confirmationEmail: 'Подтверждение e-mail контактного лица',
  emailNotVerified: 'E-mail пока не подтвержден.',
  phoneNotVerified: 'Телефон пока не подтвержден.',
  avatarVisible: 'Отображение аватара включено',
  initialsVisible: 'Включено отображение инициалов',
  userHasBeenSuccessfully: 'Пользователь успешно',
  removedFromBlackList: 'удален из черного списка',
  addedToBlackList: 'добавлен в черный список',
  yourProfile: 'Ваш профиль',
  open: 'открыт',
  closed: 'закрыт',
  successfullySaved: 'Успешно сохранено',
  membershipSuccessfullyChanged: 'Членство в Товариществе успешно изменено',
  userHasBeenSuccessfullyDeleted: 'Пользователь успешно удален',
  updateStatus: 'Статус обновления данных: ',
  invalidData: 'Не все поля содержат валидные данные',
  ownershipHasBeenSuccessfullyAdded: 'Собственность успешно добавлена',
  emailAlreadyInUse: 'Вы пытаетесь добавить электронную почту, которая уже используется другим пользователем! Данные не будут сохранены.',
  phoneAlreadyInUse: 'Такой номер телефона уже существует',
  phoneAlreadyInUse2: 'Вы пытаетесь добавить номер телефона, который уже используется другим пользователем! Данные не будут сохранены.',
  occurredWhenCreatingUser: 'Произошла ошибка при создании пользователя',
  settingsHaveBeenSuccessfullyChanged: 'Настройки успешно изменены!',
  failedToChangeSettings: 'Не удалось поменять настройки',
  occurredErrorWhenRequestingServer: 'Произошла ошибка при запросе на сервер',
  canNotDeleteMainEmail: 'Нельзя удалить основной email',
  canNotDeleteMainPhone: 'Нельзя удалить основной номер телефона',
  avatarSuccessfullyUploaded: 'Аватар успешно загружен',
  ownershipHasBeenSuccessfullyDeleted: 'Помещение было успешно удалено',
  yourPasswordHasBeenChanged: 'Ваш пароль изменен!',
  dataNotSaved: 'Данные не сохранены!',
  settingsHaveBeenSuccessfullySaved: 'Ваши настройки успешно сохранены!',
  socialNetworkHasBeenSuccessfullyAdded: 'К вашему аккаунту успешно добавлена социальная сеть',
  socialNetworkHasBeenSuccessfullyRemoved: 'Социальная сеть успешно отвязана от вашего аккаунта',
  youMustSendFlagToConfirm: 'Необходимо прислать флаг согласия с добавлением',
  statusOfRequest: 'Статус запроса'
};