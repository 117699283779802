"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  goToEVoting: 'Перейти в электронное голосование',
  goToNormalVoting: 'Перейти в бумажный бюллетень',
  scannedCopy: 'Скан-копия бумажного бюллетеня',
  agendaIssues: 'Вопросы повестки дня',
  questionNumber: '№ вопроса',
  userParticipant: 'Решение участника',
  noOneBulletinFiles: 'Нет ни одной страницы скан-копии бюллетеня',
  pageIsMissing: 'Страница {{number}} отсутствует!',
  voiceHasNotCountedYet: 'Голос пока не учтен',
  voiceHasNotCounted: 'Голос не учтен',
  eVotingIsNotAccepted: 'В данном собрании не учитывается электронное голосование',
  acceptedByEVoting: 'Голос учтен по электронному голосованию',
  acceptedByPaper: 'Голос учтен по бумажному бюллетеню',
  paperBulletinIsApproved: 'Бумажный бюллетень утверждён.',
  paperBulletinIsApprovedMyBulletin: 'Спасибо, что Вы проголосовали, используя бумажный бюллетень.',
  paperBulletinIsNotApproved: 'Бумажный бюллетень не утверждён.',
  paperBulletinIsNotApprovedMyBulletin: 'К сожалению, в данном собрании учитываются только бумажные бюллетени, и Ваш бумажный бюллетень пока не утверждён.',
  userIsVotedByEVoteButInThisMeetingAcceptedOnlyPaperBulletins: 'Участник проголосовал электронно, но в данном собрании учитываются только бумажные бюллетени.',
  userIsVotedByEVoteButEVoteIsIgnored: 'Участник проголосовал электронно, но в данном собрании электронное голосование не учитывается.',
  userIsVotedByEVoteButEVoteIsIgnoredMyBulletin: 'Вы проголосовали электронно, но в данном собрании учитываются только бумажные бюллетени.',
  userIsNotVotedByEVoteButEVoteIsIgnored: 'Участник не проголосовал электронно,\nно в данном собрании учитываются только бумажные бюллетени.',
  userIsNotVotedByEVoteButEVoteIsIgnoredMyBulletin: 'Вы не проголосовали электронно, но в данном собрании учитываются только бумажные бюллетени.',
  userIsNotVotedByEVoteButEVoteIsAllowed: 'Участник не проголосовал электронно.\nВ данном собрании электронное голосование учитывается.',
  userIsNotVotedByEVoteButEVoteIsAllowedMyBulletin1: 'Вы можете проголосовать электронно, используя мобильное приложение.',
  userIsNotVotedByEVoteButEVoteIsAllowedMyBulletin2: 'Обратите внимание, что бумажный бюллетень имеет приоритет над электронным голосованием, поэтому если бумажный бюллетень будет утверждён, то результаты Вашего голосования будут учитываться по нему, а не по электронному голосованию.',
  userIsVotedByEVoteButYouCantChangeEVote: 'Участник проголосовал электронно.\nВы не можете изменить электронное голосование участника.',
  userIsVotedByEVoteMyBulletin: 'Спасибо, что Вы проголосовали электронно.',
  noPaperBulletin: 'Нет бумажного бюллетеня.',
  noPaperBulletinMyBulletin1: 'К сожалению, в данном собрании учитываются только бумажные бюллетени. Ваш бумажный бюллетень не загружен. <a data-action="downloadBulletin" style="font-weight: 700; cursor: pointer">Скачайте свой бумажный бюллетень</a>, проголосуйте, подпишите и сдайте его организаторам собрания.',
  noPaperBulletinMyBulletin2: 'Нет бумажного бюллетеня. <a data-action="downloadBulletin" style="font-weight: 700; cursor: pointer">Скачайте свой бумажный бюллетень</a>, проголосуйте, подпишите и сдайте его организаторам собрания.',
  noPaperBulletinMyBulletin3: 'Проголосуйте электронно, используя мобильное приложение, или <a data-action="downloadBulletin" style="font-weight: 700; cursor: pointer">скачайте свой бумажный бюллетень</a>, проголосуйте, подпишите и сдайте его организаторам собрания.',
  userIsVotedByEVoteButBulletinVoteIsPriority: 'Участник проголосовал электронно, но бумажный бюллетень имеет приоритет над электронным голосованием. Поэтому результаты голосования учитываются по бумажному бюллетеню.',
  userIsVotedByEVoteButBulletinVoteIsPriorityMyBulletin: 'Вы проголосовали электронно, но бумажный бюллетень имеет приоритет над электронным голосованием. Поэтому результаты голосования учитываются по бумажному бюллетеню.',
  helperText1: 'Если у Вас есть вопросы, обратитесь\nк ',
  helperText2: 'собрания.',
  toPresident: 'Председателю',
  toSecretary: ' Секретарю',
  userIsNotVotedByEVote: 'Участник не проголосовал электронно.',
  userIsNotVotedByEVoteMyBulletin: 'Даже если Вы проголосуете электронно, это не поменяет результаты голосования, потому что бумажный бюллетень имеет приоритет над электронным голосованием.',
  userIsVotedByEVote: 'Участник проголосовал электронно.',
  yes: 'За',
  yesForProjectNumber: 'За {{number}}-й проект',
  against: 'Против',
  againstAll: 'Против всех',
  abstained: 'Воздержался',
  abstainedItem: 'Воздержаться',
  spoiled: 'Испорчено',
  notVoted: 'Не проголосовано',
  maximumCandidates_0: 'В данном голосовании возможно выбрать только {{count}} кандидата',
  maximumCandidates_1: 'В данном голосовании возможно выбрать только {{count}} кандидатов',
  maximumCandidates_2: 'В данном голосовании возможно выбрать только {{count}} кандидатов',
  recomended: 'Управляющий рекомендует',
  notRecomended: 'Управляющий не рекомендует',
  select: 'Выберите',
  selectMore: 'Выберите ещё',
  candidates_0: 'кандидата',
  candidates_1: 'кандидатов',
  candidates_2: 'кандидатов',
  cantSelectMore: 'Кандидатов больше выбрать нельзя',
  selectButton: 'Выбрать',
  previousParticipant: 'Предыдущий участник',
  nextParticipant: 'Следующий участник',
  votingEnded: 'Голосование завершено. Изменить нельзя.',
  cantVoteHasNoBulletin: 'Вы не можете выбирать решения, потому что нет скан-копии бюллетеня.',
  clickForEdit: 'Для изменения решения нажмите на кнопку «Редактировать».',
  clickToMoreMenuForEdit: 'Для изменения решения нажмите дополнительное меню <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">' + '<path d="M7.67969 6C7.9449 6 8.19926 6.10536 8.38679 6.29289C8.57433 6.48043 8.67969 6.73478 8.67969 7C8.67969 7.26522 8.57433 7.51957 8.38679 7.70711C8.19926 7.89464 7.9449 8 7.67969 8C7.41447 8 7.16012 7.89464 6.97258 7.70711C6.78504 7.51957 6.67969 7.26522 6.67969 7C6.67969 6.73478 6.78504 6.48043 6.97258 6.29289C7.16012 6.10536 7.41447 6 7.67969 6ZM7.67969 3C7.9449 3 8.19926 3.10536 8.38679 3.29289C8.57433 3.48043 8.67969 3.73478 8.67969 4C8.67969 4.26522 8.57433 4.51957 8.38679 4.70711C8.19926 4.89464 7.9449 5 7.67969 5C7.41447 5 7.16012 4.89464 6.97258 4.70711C6.78504 4.51957 6.67969 4.26522 6.67969 4C6.67969 3.73478 6.78504 3.48043 6.97258 3.29289C7.16012 3.10536 7.41447 3 7.67969 3ZM7.67969 0C7.9449 0 8.19926 0.105357 8.38679 0.292893C8.57433 0.48043 8.67969 0.734784 8.67969 1C8.67969 1.26522 8.57433 1.51957 8.38679 1.70711C8.19926 1.89464 7.9449 2 7.67969 2C7.41447 2 7.16012 1.89464 6.97258 1.70711C6.78504 1.51957 6.67969 1.26522 6.67969 1C6.67969 0.734784 6.78504 0.48043 6.97258 0.292893C7.16012 0.105357 7.41447 0 7.67969 0Z" fill="#263238"/>' + '<path d="M1.74 6.28795L0 4.01995L1.74 1.75195H2.532L0.828 4.01995L2.532 6.28795H1.74ZM3.876 6.28795L2.136 4.01995L3.876 1.75195H4.668L2.964 4.01995L4.668 6.28795H3.876Z" fill="#263238"/>' + '<path d="M11.4717 6.28795H10.6797L12.3837 4.01995L10.6797 1.75195H11.4717L13.2117 4.01995L11.4717 6.28795ZM13.6077 6.28795H12.8157L14.5197 4.01995L12.8157 1.75195H13.6077L15.3477 4.01995L13.6077 6.28795Z" fill="#263238"/>' + '</svg>' + ' в правом верхнем углу и выберите пункт «Редактировать бюллетень».',
  clickHereForEdit: 'Нажмите здесь, чтобы изменить решение.',
  canEVoteOnlyMobileApp: 'Проголосовать электронно можно во вкладке «Вопросы / Голосование»',
  canEVoteOnlyParticipant: 'Проголосовать электронно может только сам участник собрания.',
  eVoteIgnored: 'Проголосовано электронно. Не учитывается в итогах собрания, потому что в данном собрании учитываются только бумажные бюллетени.',
  eVoteNothingIgnored: 'Не проголосовано электронно. Не учитывается в итогах собрания, потому что в данном собрании учитываются только бумажные бюллетени.',
  eVoteAcceptedAsMinor: 'Проголосовано электронно. Не учитывается в итогах собрания, потому что есть утвержденный бумажный бюллетень.',
  canEditEvoteButIgnored: 'К сожалению, в данном собрании электронное голосование не учитывается.',
  canEditEvoteButHasPaperBulletin: 'Поскольку уже есть утвержденный бумажный бюллетень, то решения будут учитываться по бумажному бюллетеню, а не по электронному голосованию.',
  saveAndSubmitForApproval: 'Сохранить и Вынести на утверждение',
  submitForApproval: 'Вынести на утверждение',
  cancelEdit: 'Отменить редактирование',
  confirmThereIsAuthenticBulletin: 'Подтверждаю, что есть подлинный бюллетень',
  chooseSolutionForAllIssues: 'Для вынесения бюллетеня на утверждение выберите решения по всем вопросам повестки дня. Пока ещё есть вопросы, в которых проставлено «Не проголосовано».',
  confirmThereIsAuthenticBulletinTooltipText: 'Подтвердите, что имеете на руках подлинный бюллетень. Выделите чек-бокс слева.',
  submit: 'Утвердить',
  edit: 'Редактировать',
  informationAboutTrustedPerson: 'Информация о доверенном лице',
  copyLink: 'Скопировать ссылку',
  linkCopied: 'Ссылка скопирована в буфер обмена',
  editBulletin: 'Редактировать бюллетень',
  downloadScanCopie: 'Скачать скан бюллетеня',
  cantDownloadScanCopie: 'Нельзя скачать. Нет скан-копии бюллетеня.',
  deleteBulletin: 'Удалить бюллетень',
  delete: 'удалить',
  cancel: 'отменить',
  requiredComment: 'Обязательный комментарий',
  withoutCommentCantDelete_0: 'Объясните остальным пользователям свое решение. Без комментария файл не будет удалён.',
  withoutCommentCantDelete_1: 'Объясните остальным пользователям свое решение. Без комментария файлы не будут удалёны.',
  withoutCommentCantDelete_2: 'Объясните остальным пользователям свое решение. Без комментария файлы не будут удалёны.',
  cancelAutomaticRecognition_0: 'Удаление файла приведёт к отмене результатов автоматического распознавания бюллетеня.',
  cancelAutomaticRecognition_1: 'Удаление файлов приведёт к отмене результатов автоматического распознавания бюллетеня.',
  cancelAutomaticRecognition_2: 'Удаление файлов приведёт к отмене результатов автоматического распознавания бюллетеня.',
  clickToDeleteButton_0: 'Если Вы действительно хотите удалить файл, нажмите на кнопку «Удалить».',
  clickToDeleteButton_1: 'Если Вы действительно хотите удалить файлы, нажмите на кнопку «Удалить».',
  clickToDeleteButton_2: 'Если Вы действительно хотите удалить файлы, нажмите на кнопку «Удалить».',
  afterDeleteFilesTransfered_0: 'Удаляемый файл будет перенесён во вкладку «Нераспознанные документы». ',
  afterDeleteFilesTransfered_1: 'Удаляемые файлы будут перенесёны во вкладку «Нераспознанные документы». ',
  afterDeleteFilesTransfered_2: 'Удаляемые файлы будут перенесёны во вкладку «Нераспознанные документы». ',
  deleteVoting: 'Удаление бюллетеня приведёт к удалению результатов голосования участника.',
  presidentAndSecretaryHasEmail: 'Участник собрания, если он зарегистрирован в сервисе Сквер, Председатель и Секретарь получат автоматические уведомления об этом.',
  afterDeleteBulletin_0: 'Удаление файла приведёт к тому, что статус бюллетеня поменяется на «Ожидает обработки» и будут сброшены все результаты голосования.',
  afterDeleteBulletin_1: 'Удаление файлов приведёт к тому, что статус бюллетеня поменяется на «Ожидает обработки» и будут сброшены все результаты голосования.',
  afterDeleteBulletin_2: 'Удаление файлов приведёт к тому, что статус бюллетеня поменяется на «Ожидает обработки» и будут сброшены все результаты голосования.',
  protocolPublishing: 'Нельзя удалить. Публикуется протокол по результатам собрания.',
  canEditProtocolPublishing: 'Нельзя редактировать. Публикуется протокол по результатам собрания.',
  cantDeleteMeetinEnded: 'Нельзя удалить. Собрание завершилось.',
  cantEditMeetinEnded: 'Нельзя редактировать. Собрание завершилось.',
  cantDeleteStatusNothing: 'Нельзя удалить. Нет скан-копии бюллетеня.',
  cantEditStatusNothing: 'Нельзя редактировать. Нет скан-копии бюллетеня.',
  allFilesDeleted: 'Все скан-копии страниц бюллетеня были перенесены во вкладку «Нераспознанные документы»',
  waitingForApprovalFromPresident: 'Бюллетень ожидает утверждения Председателя',
  waitingForApprovalFromSecretary: 'Бюллетень ожидает утверждения Секретаря',
  waitingForApproval: 'Бюллетень ожидает утверждения и Председателя, и Секретаря',
  bulletinApproved: 'Бюллетень утвержден',
  sendToTelegram: 'PDF файл со скан-копиями бюллетеня выслан в  Telegram-bot',
  sendToTelegramMyBulletin: 'Ваш бюллетень выслан. Проверьте Ваш Telegram-bot. ',
  sendToEmailMyBulletin: 'Ваш бюллетень выслан. Проверьте Ваш e-mail {{email}}. ',
  sendToEmailOrTelegram: 'Мы отправим запрошенные Вами документы в push-уведомлении, в сообщении в Telegram-bot, если он у Вас подключен и в сообщении по электронной почте, если она подтверждена в Вашем аккаунте. Проверьте любой из данных каналов через 2-3 минуты.',
  sendToEmail: 'Архив со скан-копиями выбранных страниц бюллетеня выслан. Проверьте Ваш e-mail {{email}}. ',
  sentToTelegram: 'Архив со скан-копиями бюллетеня выслан. Проверьте Ваш Telegram-bot. ',
  cantDownloadBulletinText1: 'Мы отправляем бюллетень только по электронной почте или в мессенджере Telegram. К сожалению, в Вашем профиле их пока нет.',
  cantDownloadBulletinText2: 'Выберите какой способ Вам удобнее:',
  cantDownloadBulletinText3: '1. Подключить ',
  cantDownloadBulletinText4: '2. Подключить ',
  cantDownloadBulletinText5: '3. Скачайте мобильное приложение:',
  bulletinWasAccepted: 'Бюллетень уже утверждён и Председателем, и Секретарём. Участник был проинформирован об этом.',
  ifEditBulletin: 'Редактирование бюллетеня приведёт к отмене результатов голосования участника. Председатель и Секретарь должны будут заново утвердить бюллетень.',
  userHasEmail: 'Участник голосования, если он зарегистрирован в сервисе Сквер, получит автоматическое уведомление о внесении изменений в его бумажный бюллетень.',
  clickToEditButton: 'Если Вы действительно хотите отредактировать бюллетень, нажмите на кнопку «Редактировать». После этого будет включён режим редактирования и Вы сможете изменить результаты голосования по бумажному бюллетеню.',
  bulletinWasProcessed: 'Бюллетень уже был ранее обработан.',
  allBulletinNeedToAccept: 'Каждый бумажный бюллетень должен быть утверждён и Председателем, и Секретарём собрания.',
  editingModeStart: 'Режим редактирования включен. Теперь Вы можете изменить решения участника.',
  youHaveEditingMode: 'Вы уже в режиме редактирования.',
  forStartEditingMode: 'Нажмите для включения режима редактирования результатов голосования.',
  stopEditing: 'Отменить редактирование',
  youWantToStopEdit: 'Вы действительно хотите отменить внесенные изменения?',
  allDataHasBeenReturned: 'Все внесенные данные будут потеряны.',
  continueWithoutSaving: 'Продолжить без сохранения',
  exitWithoutSaving: 'Вы уверены, что хотите выйти без сохранения информации?<br><br>Все внесенные данные будут потеряны.',
  isNotAllPagesInBulletinDescriptionText1_0: 'Отсутствует страница {{pageNums}} скан-копии бюллетеня.',
  isNotAllPagesInBulletinDescriptionText1_1: 'Отсутствуют страницы {{pageNums}} скан-копии бюллетеня.',
  isNotAllPagesInBulletinDescriptionText1_2: 'Отсутствуют страницы {{pageNums}} скан-копии бюллетеня.',
  isNotAllPagesInBulletinDescriptionTextForRole2: 'Вы уверены, что хотите утвердить такой бюллетень? Если да, то оставьте обязательный комментарий и объясните своё решение.',
  isNotAllPagesInBulletinDescriptionText2: 'Вы уверены, что хотите вынести такой бюллетень на утверждение? Если да, то оставьте обязательный комментарий и объясните свое решение.',
  isNotAllPagesInBulletinDescriptionText3: ' Этот комментарий обязателен и будет виден участнику собрания, Председателю и Секретарю собрания.',
  isNotAllPagesInBulletinDescriptionText4ForRole: 'Без комментария утвердить такой бюллетень нельзя.',
  isNotAllPagesInBulletinDescriptionText4: 'Без комментария утвердить такой бюллетень нельзя.',
  makeChangesToVotingResultsForApproval: 'Вы не внесли никаких изменений, поэтому нечего сохранять.',
  normalVoteResult: 'Результат голосования по бумажному бюллетеню.',
  eVoteResult: 'Результат электронного голосования.',
  pageScans: 'Скан страниц бюллетеня',
  downloadMyBulletin: 'Скачать свой бюллетень',
  startVoting: 'Начать голосование',
  goToApp: 'Перейти в приложение',
  downloadMyBulletinTooltip: 'В зависимости от стадии собрания и от действий организаторов Вы получите архив, в котором могут быть следующие документы:<br/><br/>' + '<ol>' + '<li>Именной бланк бумажного бюллетеня</li>' + '<li>Скан-копии страниц своего бумажного бюллетеня, которые сейчас находятся в Вашей карточке голосования</li>' + '<li>После завершения собрания, при условии, что Вы голосовали электронно, файл формата PDF с отметками сервиса Сквер о результатах Вашего электронного голосования</li>' + '</ol>',
  cantDownloadMyBulletinTooltip: '<ol>' + '<li>Вы не проголосовали электронно и</li>' + '<li>Ваш бумажный бюллетень не был загружен в сервис Сквер. </li>' + '</ol> <br/>' + 'Вам нечего скачивать.',
  voting: 'Голосование',
  votingInApp: 'голосовать в приложении',
  canEVoteOnlyMobileAppModal: 'Проголосовать электронно, просмотреть подробную информацию об электронном голосовании и бумажном бюллетене можно только в мобильном приложении.',
  canViewInfoOnlyMobilaAppModal: 'Посмотреть подробную информацию об электронном голосовании и бумажном бюллетене можно только в мобильном приложении.',
  ifYouDontHaveMobileApp: 'Если у Вас не установлено мобильное приложение, можете скачать его по одной из кнопок ниже.',
  youCanDownloadBulletin: 'Если Вы не хотите голосовать через мобильное приложение, Вы можете скачать бумажный бюллетень, заполнить его, подписать и сдать организаторам собрания до ',
  fileWasRemoved: 'Файл был удален. Смотрите подробнее в истории изменений.',
  noPage: 'Нет страницы бюллетеня. Загрузка документов возможна во вкладке «Нераспознанные документы».',
  page: 'стр.',
  formReset: 'Изменения отменены. Бюллетень находится в том же виде и в том же статусе, в котором он был до редактирования.',
  whyYouEditBulletin: 'Объясните причину, по которой Вы решили редактировать бюллетень, чтобы и участник собрания, и Секретарь, и Председатель понимали, почему Вы вносите изменения. ',
  mandatoryComment: 'Без обязательного комментария внесённые изменения не будут сохранены.',
  changesAdded: 'Изменения внесены. ',
  selectFiles: 'Выберите файлы для отправки Вам на E-mail или Telegram-bot.',
  noUploadedScans: 'Нет загруженных скан копий бюллетеня. Со сканами страниц ничего сделать нельзя.',
  allScansAreRemoved: 'Все сканы страниц бюллетеня удалены. Со сканами страниц ничего сделать нельзя.',
  selectFilesForRemoval: 'Выберите файлы для удаления.',
  filesRemoved_0: 'Скан страницы бюллетеня успешно удален',
  filesRemoved_1: 'Сканы страниц бюллетеня успешно удалены',
  filesRemoved_2: 'Сканы страниц бюллетеня успешно удалены',
  viewScans: 'Нажмите для просмотра сканов страниц бюллетеня ',
  noAppeals: 'Подсказок нет',
  showAppeals: 'Показать подсказки',
  hideAppeals: 'Скрыть подсказки',
  addEmail: 'Добавьте ваш актуальный Email и нажмите на кнопку «СОХРАНИТЬ» вверху.',
  confirmEmail: 'Нажмите на кнопку «Подтвердить», после чего на указанный e-mail придёт сообщение со ссылкой. Нажмите на ссылку в сообщении, и мы убедимся, что это Ваш e-mail. После этого, Вы сможете вернуться в собрание.',
  connectTelegram: 'Нажмите на кнопку «Подключить Telegram-bot», далее следуйте инструкциям в Telegram-bot и подключите его. После этого, Вы сможете вернуться в собрание.',
  damagedTooltip: 'Выбирайте «Испорчено», если не можете однозначно выбрать решение пользователя. Например, не проставлены отметки вообще, одновременно проставлены отметки в нескольких чек-боксах или непонятно, где именно проставлена отметка.',
  rotatePhoneWarningText: 'Вы хотите просмотреть карточку голосования. Для удобства обработки информации и просмотра карточки голосования, или переверните экран в горизонтальную ориентацию, или перейдите в версию системы Сквер на Вашем ноутбуке или компьютере (или любом другом большом экране).',
  rotatePhoneWarningTitle: 'Просмотр карточки голосования',
  exitEditMode: 'Вы сейчас находитесь в режиме редактирования бюллетеня.<br><br>' + 'Прежде чем перейти к удалению файла/файлов, нужно выйти из режима редактирования.<br><br>' + 'Вы уверены, что хотите выйти из режима редактирования без сохранения результатов редактирования?',
  cantMettingAllow: 'Вам запрещено голосовать в собрании.',
  availableIn: 'Доступно в',
  downloadedIn: 'Загрузите в',
  virtualVotingSaved: 'Электронное голосование успешно сохранено.',
  saveEditing: 'Изменить голосование',
  dontChangeVoting: 'Не изменять голосование',
  saveVote: 'Сохранить голосование',
  dontVote: 'Не голосовать',
  pleaseVoteToAllQuestion: 'Пожалуйста, проголосуйте по всем вопросам',
  confirmPhoneNumber: 'подтвердить  телефон',
  forVotingConfirmPhoneNumber: 'Чтобы проголосовать Вам необходимо сначала подтвердить свой номер мобильного телефона.',
  costOfEachNewVote: 'Стоимость каждого нового голосования - {{cost}} рублей.  Если Вы действительно хотите еще раз изменить  свое голосование, необходимо предварительно оплатить тариф.',
  thisMeetingHasVotingWithPEP: 'В собрании применяется двойная аутентификация. Для сохранения результатов голосования нажмите на кнопку «{{buttonName}}».',
  addPhoneNumber: 'Добавьте Ваш актуальный номер телефона и подтвердите его, после чего, Вам прийдет СМС ' + 'со ссылкой. Нажмите на ссылку, и мы убедимся, что это Ваш номер телефона. После этого, Вы сможете ' + 'вернуться в собрания и продолжить голосование с простой электронной подписью (ПЭП).',
  clickToConfirmPhoneNumber: 'Нажмите на кнопку "Подтвердить", после чего, Вам прийдет СМС со ссылкой. Нажмите на ссылку, ' + 'и мы убедимся, что это Ваш номер телефона. После этого, Вы сможете вернуться в собрания и продолжить ' + 'голосование с простой электронной подписью (ПЭП).'
};