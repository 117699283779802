"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  yourTariffShort: 'Your plan',
  tariffs: 'Plans',
  allPlans: 'All plans',
  payment: 'Payment',
  limits: 'Limits',
  meetings_0: '{{count}} meeting',
  meetings_1: '{{count}} meetings',
  meetings_2: '{{count}} meetings',
  polls_0: '{{count}} poll',
  polls_1: '{{count}} of the survey',
  polls_2: '{{count}} polls',
  messages_0: '{{count}} message',
  messages_1: '{{count}} messages',
  messages_2: '{{count}} messages',
  mailings: 'E-mail newsletters',
  smsMailings: 'SMS newsletters',
  editingBulletinPossibility: 'Ability to edit bulletins',
  additionalMailings: 'as well as automatic mailings of the service',
  mettingTemplatesAccess: 'Access to template meetings and surveys',
  massDocumentsSigning: 'Mass signing of documents with SES (simple electronic signature)',
  perYear: 'per year',
  buyTariff: 'Buy',
  buyTariffLimitsPage: 'Buy plan',
  payUpToTariff: 'Top up plan',
  free: 'Free',
  standart: 'Standart',
  business: 'Business',
  premium: 'Premium',
  selectTariff: 'Choose the plan that suits your needs.',
  yourTariff: 'Your tariff plan',
  limitIncrease: 'Limit extension',
  limitIncreasePrice: 'Total cost of selected new limits',
  limitIncreaseDifferenceComment: 'The new limits will be available immediately after payment, extending the limits of the current tariff.',
  upTo: 'till {{date}}',
  daysLeft_0: '{{count}} day left',
  daysLeft_1: '{{count}} days left',
  daysLeft_2: '{{count}} days left',
  trialIsActiveLabel: 'Trial plan activated',
  selectTariffExtended: 'Select the rate you are interested in.',
  selectTariffComment: 'You can either buy an existing plan again or choose a new one. Then all unused limits will be added to the new plan. The tariff plan will be effective immediately after payment. The validity period is 1 year.',
  selectNewTariff: 'Buy a new tariff',
  paymentLimitIncreaseTitle: 'Pay for limit extension',
  selectNewTariffComment: 'You can either buy an existing plan again or choose a new one. Then all unused limits will be added to the new tariff plan. The tariff plan will be effective immediately after payment. The validity period is one year.',
  payUpToNewTariff: 'Pay up to the new tariff',
  payUpToNewTariffComment: 'You will be able to pay the difference in price between your existing plan and the new plan. When you choose the surcharge, you increase your limits and do not change the validity of your existing tariff. Please note that you can only pay for the new tariff if you choose the more expensive tariff.',
  a: 'A)',
  b: 'B)',
  unlimitMettings: 'Unlimited number of meetings',
  meetingsAreUnlimited: 'Meetings are not restricted',
  unlimitPolls: 'Unlimited number of surveys',
  unlimitEmails: 'Unlimited number of messages',
  currentPaymentMethod: 'Current method of payment',
  customizePaymentMethod: 'Customize payment method',
  payedUpTo: 'Rate paid to {{date}}',
  tariffAutoRenewal: 'Automatic tariff extension',
  willDebitedFromCard: '{{date}} the card will be charged the tariff price',
  paymentHistory: 'Transaction history',
  noPaymentHistory: 'No payment history',
  tariffLimits: 'Tariff limits',
  includedInTariff: 'Included in the tariff',
  used: 'Utilized',
  unlimit: 'Unlimited',
  notAvailable: 'Not available',
  available: 'Available',
  emailCount: 'Number of random e-mails',
  smsCount: 'Number of SMS',
  meetingsCount: 'Number of meetings held',
  pollsCount: 'Number of surveys conducted',
  bulletinEdit: 'Editing bulletins',
  templateMeetingsAvailability: 'Availability of template meetings and surveys',
  registryActualization: 'Updating the data of the register of owners',
  userItemPercents_0: 'Used {{count}}% {{item}}',
  userItemPercents_1: 'Used {{count}}% {{item}}',
  userItemPercents_2: 'Used {{count}}% {{item}}',
  limitIsOver: 'Limit exhausted. Buy a tariff with more {{item}}',
  usedMessages: 'the user has turned off receiving messages. You cannot message them',
  usedMeetings: 'gatherings',
  usedPolls: 'polls',
  yourCurrentTariff: 'Your current rate',
  youHaveSelectedTariff: 'You have selected a rate plan',
  payUpDiffComment: 'You need to pay {{diff}} the difference between the cost of the current Standard plan {{current}} and the new Business plan {{new}}.',
  payUpDiffLimitsComment: 'The validity period and limits already used will not change, but the limits included in the new tariff will increase.',
  willBeInNewTariff: 'Will be in the new tariff',
  haveNow: 'There is now',
  willBeAfterBuy: 'It will be after the purchase',
  includedInNewTariff: 'Included in the new tariff',
  limitIncreaseTariffDiffColumnTitle: 'Selected limit extensions',
  willTransferFromOldTariff: 'Will move from the old tariff',
  noGoBack: 'No, to go back',
  buyingTariffLimitsComment1: 'The new tariff plan will be effective immediately after payment. The validity period is 1 year.',
  buyingTariffLimitsComment2: 'All unused limits of the previous plan will be added to the limits of the selected plan. The amount of provided file storage will remain the same.',
  yourOrder: 'Your order',
  tariff: 'Tariff',
  payUpToTariff2: 'Additional payment to the tariff',
  tariffValidityPeriod: 'Tariff validity period',
  limitValidityPeriod: 'Validity period of limits',
  paymentFailure: "The payment didn't go through",
  paymentFailureMessage: 'The payment has not been made and the money has not been debited. Please pay again',
  paymentSuccess: 'You have successfully paid for your order!',
  paymentSuccessEmailMessage: 'Your e-mail: {{email}} all order details have been sent.',
  paymentSuccessPhoneMessage: 'All order details have been sent to your phone number: {{phone}}.',
  autoRenewalWarning1: 'You enable auto-renewal of the tariff: {{tariff}}',
  autoRenewalWarning2: '{{date}} card {{card}} will be charged a fee of {{sum}}.',
  autoRenewalTurnOffMessage: 'You have successfully disabled auto-renewal of the tariff!',
  autoRenewalTurnOffWarning1: 'You disable auto-renewal of the tariff: {{tariff}}',
  autoRenewalTurnOffWarning2: '{{date}} will be automatically transferred to the free tariff.',
  tariffPayUpComment1: "You'll need to pay extra",
  tariffPayUpComment2: 'the difference between the cost of the current tariff plan',
  tariffPayUpComment3: 'and a new rate plan',
  tariffPayUpComment4: 'The tariff term will remain the same - "{{date}}".',
  tariffPayUpComment5: 'The limits will increase.',
  pollsLimitExceed: 'The survey limit for your plan has been exhausted. You cannot take surveys. You need to go to <a href=\\"{{link}}\\">tariffs</a> and buy another tariff.',
  templatePollsAccessDenied: 'There is no access to survey templates at the current rate. You need to go to <a href=\\"{{link}}\\">tariffs</a> and buy another tariff.',
  emailAndSmsLimitsExceed: 'You can\'t make e-mail/sms distribution, as the current tariff limit is exhausted. To send selected messages you need to pay the new <a href=\\"{{link}}\\">tariff</a> first',
  emailLimitExceed: 'You can\'t send e-mail, because at the current tariff you don\'t have enough e-mail limit. You need to go to <a href=\\"{{link}}\\">tariffs</a> and buy another tariff',
  smsLimitExceed: 'You can\'t send sms because you don\'t have enough sms limit at your current tariff. You need to go to <a href=\\"{{link}}\\">tariffs</a> and buy another tariff',
  emailLimitExceedWithoutLink: 'You can\'t send e-mail, because at the current tariff you don\'t have enough e-mail limit. You need to go to "Plans" and buy another tariff',
  smsLimitExceedWithoutLink: 'You can\'t send sms because you don\'t have enough sms limit at your current tariff. You need to go to "Plans" and buy another tariff',
  buyTariffTooltip: 'All unused limits of the current tariff will be added to the new tariff. The new tariff plan will be effective immediately after payment. Its validity period is one year.',
  payUpTariffTooltip: 'You will be able to pay the difference in price between the current plan and the more expensive plan. All unused limits of the current tariff plan will be increased by the difference between the limits of the current and new tariff plan. The tariff validity period does not change.',
  notIncludedInTariff: 'Not included in the tariff',
  townSettingsIsWrong: 'To purchase the tariff, you need to fill out the house settings correctly.',
  needCadastralCode: 'The cadastral number of the building is required to calculate the tariff cost. Go to Settings to enter the cadastral number.',
  notAvailableInFreeTariff: 'The free rate does not include',
  pollAndMeetingTemplate: 'Meeting and survey templates',
  includedInPaiTariffs: 'All of these options are included in the paid plans',
  unusedLimits: 'Current limits',
  limitsDiff: 'Difference in limits',
  willBeAfterPayUp: 'It will be after the surcharge',
  willBeAfterPay: 'It will be after payment',
  messageLimitsOverheadMessage: 'You have selected {{selectedMessages}}, but in the current tariff only {{availableMessages}} is available. You can reduce the number of recipients or pay a new <a href=\\"{{link}}\\">rate</a>',
  emailMessagesSelected_0: '{{count}} e-mail newsletter message',
  emailMessagesSelected_1: '{{count}} e-mail newsletter messages',
  emailMessagesSelected_2: '{{count}} messages for e-mail distribution',
  smsMessagesSelected_0: '{{count}} message for sms distribution',
  smsMessagesSelected_1: '{{count}} messages for sms distribution',
  smsMessagesSelected_2: '{{count}} messages for sms distribution',
  emailMessagesAvailable_0: '{{count}} e-mail messages',
  emailMessagesAvailable_1: '{{count}} e-mail messages',
  emailMessagesAvailable_2: '{{count}} e-mail messages',
  smsMessagesAvailable_0: '{{count}} sms messages',
  smsMessagesAvailable_1: '{{count}} sms messages',
  smsMessagesAvailable_2: '{{count}} sms messages',
  trialTariff: 'Trial plan',
  trialTariffsMessage: 'If you are not sure about the usefulness of the premium features, you can try them for free before purchasing. To do so, activate the "<b>trial plan</b>". This feature will allow you to use the following features until the end of the current tariff: <b>survey and meeting templates</b>, <b>editing a ballot in a meeting</b>, <b>creating petitions and claims</b>, <b>sending out notifications (10 emails and 10 sms)</b>',
  willTryLater: "I'll try it later",
  activateTrialTariff: 'Activate the trial plan',
  from: 'from ',
  customTariffTooltip1: 'Suitable for experienced meeting organizers or for smaller Facilities.',
  customTariffTooltip2: 'The recommended limits depend on the number of ',
  customTariffTooltipCondo: 'of premises in the building.',
  customTariffTooltipVillage: 'of buildings in the township.',
  customTariffTooltip3: 'Select the options you want to use. You can choose not to use the recommended options, reduce or increase any option and thus change the cost.',
  customTariffTooltip4: 'Not included:',
  notInclude1: 'templated meetings;',
  notInclude2: 'bulletin editing;',
  notInclude3: 'mass signing of documents;',
  notInclude4: 'petitions;',
  notInclude5: 'claims to RDF and MC.',
  includeInTariff: 'Included in the rate:',
  meetings1: 'updating of the register;',
  meetings2: 'ballot recognition;',
  meetings3: 'storage of scanned copies of the meeting documents during the validity period of the tariff;',
  meetings4: 'meeting templates;',
  meetings5: 'bulletin editing',
  emailInfo: '{{count}} messages in addition to automatic mailings',
  smsInfo: '{{count}} messages in addition to automatic mailings',
  signing1: 'Mass signing of documents ',
  signing2: '(simple electronic signature)',
  petitions: 'Petitions',
  pretensions: 'Claims against RDF and MC',
  customName: 'Individual limits',
  recommendationHighlightLabel: 'Recommendation',
  calculatorCustomTariffIncreaseLimitsHintTitle: 'You can purchase additional tariff options. ',
  calculatorCustomTariffIncreaseLimitsHintBody: 'Select the limits you need. After payment, your current tariff limits will be extended .',
  calculatorCustomTariffCustomTariffHint: 'Select the limits you need. The new tariff plan will be effective immediately after payment. The validity period is 1 year. ',
  calculatorCustomTariffRecommendationHint1: 'When selecting limits, pay attention to the lines labeled as',
  calculatorCustomTariffRecommendationHint2: 'They are calculated based on the experience of meetings in similar homes and townships.',
  calculatorHintMessage2: 'They are calculated based on the experience of meetings in similar homes and townships.',
  calculatorLimitsNameColumnTitle: 'Tariff limits',
  calculatorAvailableLimitsColumnTitle: 'Available now',
  calculatorCustomLimitSelectColumnTitle: 'Choose the limits that suit you',
  calculatorCustomLimitPriceColumnTitle: 'Cost',
  calculatorNewLimitsAfterPurchaseColumnTitle: 'Will be available after payment',
  calculatorAvailableStorageLimitHint: "Of these, it's free: ",
  calculatorAvailableStorageLimitHintShort: 'Dismissed: ',
  calculatorCustomLimitSelectBlankValue: 'Not selected',
  calculatorCustomLimitSelectDefaultStorageValue: 'Current {{limit}}',
  calculatorCustomLimitSelectMaxStorageValue: 'Maximum {{limit}}',
  calculatorCustomLimitTotalPriceLabel: 'Total cost',
  calculatorButtonGoBack: 'Cancel',
  calculatorButtonBuyTariff: 'Pay',
  meetingsLimitName: 'Conducting the meeting',
  actializationLimitName: 'Register update',
  bulletinsLimitName: 'Recognizing ballots',
  filesLimitName: 'File storage',
  smsLimitName: 'SMS newsletters',
  emailLimitName: 'E-mail newsletters',
  pollsLimitName: 'Conducting surveys',
  pepLimitName: 'SES (simple electronic signature)',
  meetingsTooltip1: 'You can hold the meeting and process all the ballots yourself without using other limits.',
  meetingsTooltip2: 'Will be available:',
  meetingsTooltip3: 'Agenda setting;',
  meetingsTooltip4: 'All printed forms of the meeting documents;',
  meetingsTooltip5: 'Automated mailings;',
  meetingsTooltip6: 'Vote count and final ballot.',
  meetingsTooltip7: 'It will be unavailable:',
  meetingsTooltip8: 'Independent e-mail and sms mailings;',
  meetingsTooltip9: 'Ballot Recognition;',
  meetingsTooltip10: 'Storage of all meeting files for one year;',
  storageTooltip1: 'During a meeting, users can upload various files to the system: scans of ballots and meeting materials, contracts, documents, estimates, etc. Disk space is used to store uploaded files. You can pay for the required number of GB and we will display and give access to all these files for 1 (One) year.',
  storageTooltip2: 'The recommended amount of disk space for your ',
  town: 'town - ',
  village: 'village - ',
  storageTooltip3: 'You can choose more or less. If you run out of this disk space, you can always purchase the required amount.',
  storageTooltip4: 'We will stop storing files 30 days after the tariff expires.',
  bulletinsTooltip1: 'If you plan to have a large number of paper ballots used in your meeting, we recommend that you pay the ballot recognition limit.',
  bulletinsTooltip2: 'How does it work? Meeting organizers receive authentic signed ballots, photograph or scan them and upload these files to the system. Square then automatically recognizes exactly who voted on agenda items and how they voted and attaches the files to each individual voter. It remains for the Chair and the Meeting Secretary to review and approve the voting results for each participant.',
  bulletinsTooltip3: 'You will be able to pay for this feature at a later date when you hold the meeting.',
  smsTooltip1: 'In addition to automatic mailings, the user can choose to whom and when to send SMS mailings with reminders about the meeting.',
  smsTooltip2: 'The recommended number of sms messages for your ',
  smsTooltip3: '{{count}} qty',
  smsTooltip4: 'You will be able to send messages to all registered users who have their cell phone number information saved.',
  smsTooltip5: 'If you will miss sms mailings, you can always choose and pay for an additional package.',
  emailTooltip1: 'In addition to the automatic mailings, the user can choose to whom and when to send e-mail reminders about the meeting.',
  emailTooltip2: 'The recommended number of e-mail messages for your ',
  emailTooltip3: 'You will be able to send messages to all users who have email address information saved.',
  emailTooltip4: 'If you will not have enough e-mail newsletters, you can always choose and pay for an additional package.',
  actualizeTooltip1: 'If you want to update the information about owners and their ownership shares before the meeting, select the required number of updates depending on the planned number of meetings.',
  actualizeTooltip2: 'We will update the data and provide you with an up-to-date register of landlords.',
  actualizeTooltip3: 'The current register does not include information with contact details of owners: e-mail, phone number, identity documents.',
  actualizeTooltip4: 'You will be able to select and pay for this feature later when you hold the meeting.',
  actualizeTooltip5: "You cannot choose a service to Update the Registry. You must first Order and pay for the Owners' Register. After that you will be able to choose the Register Update service.",
  actualizeTooltip6: 'The fact is that the initial Registry Order costs twice as much as the Registry Update.',
  actualizeTooltip7: 'Go to Register Order',
  payUpLimits: 'Add limits',
  currentTariff: 'The current rate: ',
  modalTitle1: "We don't know the number of rooms",
  modalTitle2: 'The number of rooms is not specified in the settings',
  cantBuy: "You can't pay the fare right now because ",
  forCondo1: "we don't know the number of rooms in your building.",
  forCondo2: ' we do not know the number of rooms for one or more of the MFBs in your Facility.',
  tariffsDepend: ' Plans depend on these indicators.',
  need: 'Necessary:',
  settings: 'Settings',
  noNumber1: '1. Go to ',
  noNumber2: ' and put in ',
  noNumber11: 'cadastral number of the building.',
  noNumber12: 'cadastral numbers for each Multi-apartment complex.',
  noNumber3: '2. We will request an extract from the Unified State Register of Deeds for your home',
  noNumber33: '2. We will request an extract from the USRN for each house',
  noNumber4: ' and find out the exact number of all rooms. This may take up to {{count}} working days.',
  noNumber5: '3. After that you will be able on the same page ',
  noNumber6: ' specify the total number of premises for the Multi-apartment complex.',
  noNumber66: ' specify the total number of premises for each Multi-apartment complex.',
  noNumber7: 'After that, you will have access to paid plans',
  close: 'Close',
  goToSettings: 'Go to Settings',
  structure1: "1. We've requested extracts from the USRN",
  structure2: 'for all Multi-apartment complexes',
  structure3: "and we'll know the exact number of all the rooms soon. This may take up to {{count}} working days. We will send you a message with the file.",
  structure4: '2. After that you will be able to on the page ',
  settings1: 'the number of rooms is not specified in the multi-apartment complex Settings.',
  settings2: 'the number of rooms for one or more ICDs included in your Object is not specified in the Settings.',
  page: '1. On the page ',
  apartmentsCount1: ' specify the total number of premises for your MFB.',
  apartmentsCount2: ' specify the total number of premises for each ICD.',
  customMeetings: 'Meetings',
  customPolls: 'Polls',
  smsAndEmails: 'SMS/e-mail packages',
  actualization: 'Simple electronic signature',
  pollsTooltip1: 'You can take surveys without using other limits.',
  pollsTooltip2: 'Survey Formation;',
  pollsTooltip3: 'Automated mailings;',
  pollsTooltip4: 'Vote counting and vote totals.',
  pollsTooltip5: 'Independent e-mail and sms mailings',
  limitUnitStorageGb: 'GB',
  limitUnitStorageMb: 'MB',
  limitUnitStorageKb: 'Kb',
  limitUnitStorageBytes: 'byte',
  villageModal1: 'You cannot pay the plan now, as we do not know the number of buildings in your settlement. The plans depend on this.',
  villageModal2: ' specify the total number of structures for the township.',
  disabledTitle: 'First, select the limits',
  pepTooltipTitle: 'SES (simple electronic signature)',
  pepTooltipText1: 'The PEP is used as a means of additional authentication of assembly participants.',
  pepTooltipText2: 'How it works?',
  pepTooltipText3: 'An SMS message with a verification code is sent to the participant of the meeting to his/her cell phone number, which was previously <span style=\\"font-weight: 600\\">saved and confirmed </span> in his/her profile. Only after entering this code will the participant\'s voting results be saved.',
  pepTooltipText4: '<span style=\\"font-weight: 600; color: #EC586A\\">IMPORTANT!!!</span> This means that <span style=\\"font-weight: 600\\">only</span> users with <span style=\\"font-weight: 600\\">verified cell phone numbers</span> will be able to vote electronically. Other users will be required by the system to verify their cell phone number first.',
  pepTooltipText6: 'Each user will have the opportunity to vote twice for free in the meeting. The user will have to pay for the third and all subsequent votes himself.',
  pepTooltipPriceText: '<span style=\\"font-weight: 600; color: #EC586A\\">IMPORTANT!!!</span> This is a paid option. The cost for your meeting is {{price}} rubles.',
  pepTooltipNoPriceText: 'The cost of the option cannot be calculated until the cadastral number and the number of rooms in the settings are filled in.',
  nameOfPEPLimit: 'Dual authentication when voting at a meeting.',
  notificationDataSavedToastText: 'You did not complete sending the notification. Your message and recipient list are saved and will be displayed in the notification window if you continue sending the notification later.'
};